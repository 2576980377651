import styles from './Contact.module.scss';


const Contact = () => {
    return (
        <div className={styles.Contact}>
            <h1>Contact</h1>
        </div>
    )

}

export default Contact