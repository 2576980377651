import styles from './PatientForm.module.scss';
import {Box, Button, Checkbox, FormControlLabel, MenuItem, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import classNames from "classnames";


export default function PatientForm() {

    interface IAddress {
        street: string;
        city: string;
        state: string;
        zip: string;
        neighborhood: string;
        lock: boolean;
        country: string;
    }


    const defaultFormData = {
        name: '',
        birthday: '',
        gender: '',
        profession: '',
        cpf: '',
        school: '',
        maritalstatus: '',
        religion: '',
        email: '',
        phone: '',
        address: {
            street: '',
            city: '',
            state: '',
            zip: '',
            neighborhood: '',
            country: '',
        },

    }

    const [formData, setFormData] = useState({...defaultFormData});





    const [gender, setGender] = useState('');
    const [checkedA, setCheckedA] = useState(false);
    const [checkedB, setCheckedB] = useState(false);

    const [address, setAddress] = useState<IAddress>({
        street: '',
        city: '',
        state: '',
        zip: '',
        neighborhood: '',
        lock: false,
        country: '',
    });

    function handleControlChangeA(event: React.ChangeEvent<HTMLInputElement>) {
        setCheckedA(event.target.checked);

    }

    function handleControlChangeB(event: React.ChangeEvent<HTMLInputElement>) {
        setCheckedB(event.target.checked);

    }

    const [errors, setErrors] = useState('');


    function handleCepChange(event: React.ChangeEvent<HTMLInputElement>) {
        const cep = event.target.value;
        if (cep.length !== 8) {
            return;
        }
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then(response => response.json())
            .then(data => {
                setAddress({
                    street: data.logradouro,
                    city: data.localidade,
                    state: data.uf,
                    zip: data.cep,
                    neighborhood: data.bairro,
                    lock: true,
                    country: "Brasil",
                })
            })
    }

    const genders = [
        {
            value: 'Male',
            label: 'Male',
        },
        {
            value: 'Female',
            label: 'Female',
        },
        {
            value: 'Other',
            label: 'Other',
        },
    ]

    const handleChangeGender = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGender(event.target.value);
    }

    // function handleSubmit(event: React.ChangeEvent<HTMLInputElement>) {
    function handleSubmit(event: any) {
        event.preventDefault();

        if (checkedA && checkedB) {
            setFormData({
                address: address,
                cpf: event.target.cpf.value,
                email: event.target.email.value,
                gender: event.target.gender.value,
                maritalstatus: event.target.maritalstatus.value,
                phone: event.target.phone.value,
                profession: event.target.profession.value,
                religion: event.target.religion.value,
                school: event.target.scholl.value,
                name: event.target.name.value,
                birthday: event.target.birthday.value,

            })
            console.log(formData);
            console.log('Form submitted');

        } else {
            setErrors('ambos devem ser marcados');
        }
    }

    function handleDelete(event: React.FormEvent) {
        event.preventDefault();
        console.log('Form deleted');
    }

    // const [form, setForm] = useState({
    //         nome: '',
    //         dataNascimento: '',
    //         genero: '',
    //         cpf: '',
    //
    //
    //     }
    // )
    // const { handleSubmit, handleChange, data, errors } = useForm({
    //     validations: {
    //         name: {
    //             pattern: {
    //                 value: '^[A-Za-z]*$',
    //                 message: "You're not allowed to...",
    //             },
    //         },
    //         age: {
    //             custom: {
    //                 isValid: (value) => parseInt(value, 10) > 17,
    //                 message: 'You have to be at least 18 years old.',
    //             },
    //         },
    //         password: {
    //             required: {
    //                 value: true,
    //                 message: 'This field is required',
    //             },
    //             custom: {
    //                 isValid: (value) => value.length > 6,
    //                 message: 'The password needs to be at...',
    //             },
    //         },
    //     },
    // });


    return (
        <div className={styles.patientform__container}>

            <h2 className={styles.titulo}>Ficha de Atendimento Individual</h2>

            <Box
                component={"form"}
                className={styles.form}
                onSubmit={handleSubmit}
                onReset={handleDelete}
            >

                <p className={styles.subtitulo}>Dados Pessoais</p>
                <div className={styles.form_pessoal}>

                    <TextField type={"text"}
                               label={"Nome"}
                               variant={"outlined"}
                               name={"name"}
                    >Nome</TextField>

                    <TextField fullWidth
                               name={"birthday"}
                               type={"date"}
                               label={"Data de Nascimento"}
                               variant={"outlined"}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                    >Data de Nascimento</TextField>

                    <TextField fullWidth onChange={handleChangeGender} select={true} value={gender} label={"Gênero"}
                               name={"gender"}>
                        {
                            genders.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))
                        }
                    </TextField>


                </div>

                <p className={styles.subtitulo}>Dados Profissionais</p>

                <div className={styles.form_profissional}>
                    <TextField type={"text"}
                               label={"Profissão"}
                               variant={"outlined"}
                               name={"profession"}
                    >
                        Profissão</TextField>

                    <TextField
                        name={"cpf"}
                        type={"text"}
                        label={"CPF"}
                        variant={"outlined"}
                        inputProps={{
                            maxLength: 11,
                            minLength: 11,
                        }}


                    >CPF</TextField>

                    <TextField type={"text"} label={"Escolaridade"} variant={"outlined"}
                               name={"scholl"}>Escolaridade</TextField>

                    <TextField type={"text"} label={"Estado Civil"} variant={"outlined"} name={"maritalstatus"}>Estado
                        Civil</TextField>

                    <TextField type={"text"} label={"Religião"} variant={"outlined"}
                               name={"religion"}>Religião</TextField></div>


                <p className={styles.subtitulo}>Dados de Contato</p>

                <div className={styles.form_contato}>
                    <TextField name={"email"} type={"text"} label={"Email"} variant={"outlined"}>Email</TextField>

                    <TextField name={"phone"} placeholder={"(XX) 99999 - 9999 "} type={"tel"} label={"Telefone"}
                               variant={"outlined"}>Telefone</TextField>

                    <TextField name={"cep"} placeholder={"12345-123"} type={"cep"} label={"CEP"}
                               variant={"outlined"}
                               onChange={handleCepChange}
                    >CEP</TextField></div>


                <div className={styles.form_endereco}>
                    <TextField
                        type={"text"}
                        label={"Rua"}
                        variant={"outlined"}
                        value={address.street}
                        disabled={true}
                        name={"street"}
                    >Rua</TextField>

                    <TextField name={"number"} type={"number"} label={"Número"} variant={"outlined"}>Número</TextField>

                    <TextField name={"comp"} type={"text"} label={"Complemento"}
                               variant={"outlined"}>Complemento</TextField>

                </div>


                <div className={styles.form_endereco_int}>
                    <TextField name={"neighborhood"} type={"text"} label={"Bairro"} variant={"outlined"}
                               value={address.neighborhood}
                               disabled={true}>Bairro</TextField>
                    <TextField name={"city"} type={"text"} label={"Cidade"} variant={"outlined"} value={address.city}
                               disabled={true}>Cidade</TextField>
                    <TextField type={"text"} label={"Estado"} variant={"outlined"} value={address.state}
                               disabled={true}>Estado</TextField>
                    <TextField name={"country"} type={"text"} label={"País"} variant={"outlined"}>País</TextField>
                </div>

                <p className={styles.subtitulo}>Dados do responsável</p>
                <div className={styles.form_responsavel}><TextField type={"text"} label={"Nome do Pai"}
                                                                    variant={"outlined"}>Nome do Pai</TextField>
                    <TextField type={"text"} label={"Nome da Mãe"} variant={"outlined"}>Nome da Mãe</TextField>
                    <TextField type={"text"} label={"Nome do Cônjuge"} variant={"outlined"}>Nome do Cônjuge</TextField>
                    <TextField type={"text"} label={"Nome do Responsável"} variant={"outlined"}>Nome do
                        Responsável</TextField>
                    <TextField type={"text"} label={"Telefone do Responsável"} variant={"outlined"}>Telefone do
                        Responsável</TextField>
                    <TextField type={"text"} label={"Endereço do Responsável"} variant={"outlined"}>Endereço do
                        Responsável</TextField>
                    <TextField type={"text"} label={"Nome do Médico"} variant={"outlined"}>Nome do Médico</TextField>
                    <TextField type={"text"} label={"Telefone do Médico"} variant={"outlined"}>Telefone do
                        Médico</TextField>

                </div>

                <p className={styles.subtitulo}>Outros</p>
                <TextField className={styles.form_obs} multiline={true} rows={4} type={"text"} label={"Observações"}
                           variant={"outlined"}>Observações</TextField>


                <div className={classNames(
                    errors ? styles.error : '',
                    styles.form_checkbox
                )}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedA}
                                onChange={handleControlChangeA}
                                name="checkedA"
                            />
                        }
                        label="Eu, responsável pelo paciente, autorizo o tratamento psicológico do mesmo."
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={checkedB}
                            onChange={handleControlChangeB}
                            name="checkedA"
                        />}
                        label="Concordo com o tratamento dos meus dados pessoais de acordo com a Lei Geral de Proteção de Dados Pessoais, Lei nº 13.709/2018"
                    />
                </div>

                {errors ?
                    <div className={`${styles.error} ${styles.error__div}`}>
                        <p>{errors}</p>
                    </div> : ''}


                <div className={styles.form_buttons}>
                    <Button
                        type={"submit"}
                        endIcon={<SaveIcon/>}
                        color={"secondary"}
                        className={styles.form_button}
                        variant={"contained"}>Salvar
                    </Button>
                    <Button
                        type={"reset"}
                        startIcon={<DeleteIcon/>}
                        color={"error"}
                        className={styles.form_button}
                        variant={"contained"}>Limpar formulário</Button>
                </div>


            </Box>


        </div>
    );
}