
const Articles = () => {
    return (
        <div>
            <h1>Artigos</h1>
        </div>
    )

}

export default Articles