
import {Link, Outlet} from "react-router-dom";

function Dashboard () {
    return (
        <div>
            <div>Dashboard</div>
            <div>
                <Link to="formulario">Cadastrar Paciente</Link>

            </div>
            <Outlet/>
        </div>

    )

}

export default Dashboard;