import styles from './Main.module.scss';
import Banner from "../Banner";
import Articles from "../../pages/Articles";
import Treatments from "../../pages/Treatments";
import AboutComponent from "../AboutComponent";

const Main = () => {
    return (
        <div className={styles.Main}>
            <Banner/>
            {/*<Articles/>*/}
            <AboutComponent />
            <Treatments/>
        </div>
    )
}

export default Main;