import styles from './Rodape.module.scss';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FloatingActionButton from "../Fab";
import ScrollButton from "../ScrollTop";



const Rodape = () => {

    const instagram = 'https://www.instagram.com/psimoniquebraz/';
    const facebook = 'https://www.facebook.com/psimoniquebraz/';
    const email = 'mailto:moniquebrazpsicologa@gmail.com';
    const whatsapp = 'https://api.whatsapp.com/send?phone=5585999889767&text=Ol%C3%A1%2C%20vim%20pelo%20seu%20site%20e%20gostaria%20de%20marcar%20uma%20consulta.';


    return (


        <div className={styles.footer}>

            <FloatingActionButton/>
            <ScrollButton/>

            <div className={styles.footer__icons}>
                <a href={instagram} target="_blank" rel="noreferrer" ><InstagramIcon fontSize='large'/></a>
                <a href={facebook} target="_blank" rel="noreferrer" ><FacebookIcon fontSize='large'/></a>
                <a href={email} target="_blank" rel="noreferrer" ><EmailIcon fontSize='large'/></a>
            </div>

            {/*<div className={styles.footer__text}>*/}
            {/*    <p>Desenvolvido por <a href="https://www.linkedin.com/in/davileao/" target="_blank" rel="noreferrer">Davi Leao</a></p>*/}
            {/*</div>*/}

        </div>
    )
}

export default Rodape;