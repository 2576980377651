import styles from './AboutComponent.module.scss';
import photo from '../../assets/images/photo.jpg';
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import { NavLink} from "react-router-dom";

const AboutComponent = () => {


    return (
        <div className={styles.about__container}>

            <h1 className={styles.title}>Sobre</h1>

            <p className={styles.text}>Olá,
            sou Monique Braz, sou psicologa clínica e neuropsicóloga, sou formada em Psicologia pela Universidade de Fortaleza. Iniciei como psicóloga clínica em 2016, antes disso
            tive a oportunidade de realizar um pós-graduação na Universidad Autonoma de Barcelona , na Espanha, fui psicóloga escolar de 2014-2016.</p>

            <NavLink to={'/sobre'}
                     style={({ isActive }) =>
                         isActive ? { display: 'none' } : { display: 'block' }
                     }>
                <ArrowRightAlt className={styles.arrow__icon}/>
            </NavLink>

            <img className={styles.photo} src={photo} alt="Logo" />

            <div className={styles.whitebg}></div>

        </div>

    )

}

export default AboutComponent;