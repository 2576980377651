import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Home from './pages/Home';
import About from "./pages/About";
import Articles from "./pages/Articles";
import Treatments from "./pages/Treatments";
import PatientForm from "./pages/PatientForm";
import Main from "./components/Main";
import Contact from "./pages/Contact";
import Dashboard from "./pages/Dashboard";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Home/>}>
                <Route index element={<Main/>}/>
                <Route path="/sobre" element={<About/>}/>
                <Route path="/artigos" element={<Articles/>}/>
                <Route path="/contato" element={<Contact/>}/>
                <Route path="/tratamentos" element={<Treatments/>}/>
                <Route path="/fichacadastral" element={<PatientForm/>}/>
            </Route>
            <Route path="dashboard" element={<Dashboard/>}>
                <Route path='formulario' element={<PatientForm/>}/>
                <Route path='artigos' element={'outra coisa'}/>
            </Route>
            <Route path="*" element={'não achei'}/>

        </Routes>
    )

}

export default App;
