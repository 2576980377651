import styles from './TreatmentsComponent.module.scss';
import classNames from "classnames";

export const TreatmentsComponent = () => {

    const tratamentos = [
        {
            id: 1,
            name: 'Depressão',
            description: 'A depressão é uma doença psiquiátrica que afeta o emocional da pessoa, que passa a apresentar tristeza profunda, falta de apetite, desânimo, pessimismo, baixa auto-estima, que aparecem com frequência e podem combinar-se entre si.',
        },
        {
            id: 2,
            name: 'Transtorno Bipolar',
            description: 'O transtorno bipolar é uma alteração mental grave em que o portador sofre com oscilações no humor, variando de depressão até mania, ou seja, da tristeza profunda até a euforia extrema. Esse transtorno afeta homens e mulheres e, geralmente, aparece no início da vida adulta.',

        },
        {
            id: 3,
            name: 'Ansiedade',
            description: 'Transtornos de ansiedade são doenças psíquicas caracterizadas pela preocupação excessiva e constante de que algo ruim pode acontecer. Durante uma crise de ansiedade, as pessoas sentem muita tensão e não conseguem permanecer no momento presente, nem analisar uma situação a partir de dados reais.',
        },
        {
            id: 4,
            name: 'TOC',
            description: 'O transtorno obsessivo-compulsivo (TOC) é caracterizado pela presença de obsessões e compulsões. Obsessões são idéias, pensamentos, imagens ou impulsos repetitivos e persistentes que são vivenciados como intrusivos e provocam ansiedade. Não são apenas preocupações excessivas em relação a problemas cotidianos.',
        },
        {
            id: 5,
            name: 'Transtorno do Pânico',
            description: 'O transtorno do pânico (TP) é caracterizado por crises de ansiedade repentina e intensa com forte sensação de medo ou mal-estar, acompanhadas de sintomas físicos. As crises podem ocorrer em qualquer lugar, contexto ou momento, durando em média de 15 a 30 minutos.',

        },
        {
            id: 6,
            name: 'Fobias',
            description: '',
        }
    ];

    const bgColors = ['tumbleweed', 'blue', 'grey'];
    const n = bgColors.length;


    return (
        <div className={styles.container}>
            <div className={styles.titleDiv}>
                <span className={styles.titletext}>Tratamentos</span>
            </div>
            <div className={styles.treatments}>
                {tratamentos.slice(0, 3).map(
                    ({description, id, name}) => (
                        <div className={
                            classNames({
                                [styles.treatment__container]: true,
                                [styles[`${bgColors[(id % n + n) % n]}`]]: true
                            })}

                             key={id}>
                            <span className={styles.treatmentTitle}>0{id}.</span>
                            <h3 className={styles.treatment__name}>{name}</h3>
                            <p className={styles.treatment__description}>{description}</p>
                        </div>
                    ))}
            </div>
        </div>
    );

}


