import NavBar from "../../components/NavBar";
import Rodape from "../../components/Rodape";
import {Outlet} from "react-router-dom";

function App () {
    return (
        <>
            <NavBar />
            <Outlet />
            <Rodape />
        </>
    )

}

export default App;