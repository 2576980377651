import styles from './Banner.module.scss';
import banner from '../../assets/images/banner.jpg';

const Banner = () => {
    return (
        <>
        <div className={styles.banner}>

            <div className={styles.banner__img}>
                <img className={styles.banner__img} src={banner} alt="Banner"/>
            </div>
            <div className={styles.banner__container}>
                <div className={styles.frase}>
                    <p>"Pare e dê uma chance a si mesmo."</p>
                    <span>Aaron Beck</span>
                </div>
            </div>

        </div>
        </>
    )
}

export default Banner;