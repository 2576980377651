import styles from './AboutQualifications.module.scss';

const AboutQualifications = () => {

    const qualificacoes = [
        {
            ano: '2011',
            titulo: 'Psicologia',
            descricao: 'Graduação em Psicologia pela Universidade de Fortaleza (UNIFOR).'
        },
        {
            ano: '2011-2012',
            titulo: 'Psicologia Forense',
            descricao: 'Especialização em Psicologia Forense pela Universidad Autonoma de Barcelona.'
        },
        {
            ano: '2018-2019',
            titulo: 'Neuropsicologia',
            descricao: 'Especialização em Neuropsicologia pela UniChristus.'
        },
        // {
        //     ano: '2022-presente',
        //     titulo: 'Curso sobre Personalidade Bipolar',
        //     descricao: 'Curso de personalidade bipolar pela Universidade de Harvard.'
        // },


    ]

    const qualificacoesList = qualificacoes.reverse();


    return (
        <div className={styles.qualifications}>
            <h1 className={styles.title}>Qualificações</h1>
            <div className={styles.qualifications__container}>
                {qualificacoesList.map((qualificacao, index) => (
                    <div className={styles.qualifications__item} key={qualificacao.ano}>
                            <h3>{qualificacao.ano}</h3>
                            <p className={styles.qualifications__item__title}>{qualificacao.titulo}</p>
                            <p className={styles.qualifications__item__description}>{qualificacao.descricao}</p>
                    </div>
                ))}
            </div>
        </div>
    )

}

export default AboutQualifications;