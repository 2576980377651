import styles from './About.module.scss';
import AboutComponent from "../../components/AboutComponent";
import AboutQualifications from "../../components/AboutComponent/AboutQualifications";

const About = () => {


    return (
        <div className={styles.about__container}>

            <AboutComponent/>
            <AboutQualifications/>


        </div>

    )

}

export default About