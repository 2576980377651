import {TreatmentsComponent} from "../../components/TreatmentsComponent";

const Treatments = () => {
    return (
        <div>
            <TreatmentsComponent/>
        </div>
    )
}

export default Treatments